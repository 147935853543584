import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import PageHeader from "../../Components/PageHeader";
import strateg from "../../assets/img/strateg.webp";
import { useState } from "react";
import { useEffect } from "react";
import { servicesgetbyslug } from "../../network/Network";
import { baseUrl } from "../../network/ApiUrl";

const Strategy = () => {
  const [slug, setSlug] = useState("strategy");
  const [service, setService] = useState([]);
  const [heading, setHeading] = useState("");
  const [innerHeading, setinnerHeading] = useState("");
  const [short, setShort] = useState("");
  const [bannerImage, setBannerImage] = useState(null);
  const [newFile, setNewFile] = useState();
  const [fileupload, setFileupload] = useState();
  const [content, setContent] = useState("");
  useEffect(() => {
    let data = {
      slug: `/strategy`,
    };
    servicesgetbyslug(data)
      .then((res) => {
        console.log(res, "rrrr");
        setService(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [slug]);
  console.log("ser",service)
  return (
    <>
      <Header />

      <PageHeader pagename="Strategy" pagelinkname="Services" />

      {/* winning sec starts here */}
      <section className="winning-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="content-wrapper">
                {/* <h3>Strategy</h3> */}
                <h3>{service?.heading}</h3>
                <div className="blue-divider"></div>
                {/* <h5>Strategy To Protect Ideas and Grow Businesses.</h5> */}
                {/* <p>
               
                  agencyIP strategy can help you protect your IP and monetize it successfully. Our services enable you and your company to achieve peace of mind. The foremost responsibility of agencyIP is to maximize your success. We have a team who can assist you in developing and implementing a plan tailored to you and your company's needs and objectives.
                </p> */}
                  <p dangerouslySetInnerHTML={{ __html: service?.content }} />
                {/* <p>
                The foremost responsibility of agencyIP is to maximizing your success and IP . We have a team who can assist you in developing and implementing a plan tailored to you or your Company's needs and objectives.
                </p> */}
              </div>
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="img-wrapper img-effect sales-img">
                <figure>
                  {/* <img src={strateg} className="img-fluid" /> */}
                  <img
                    className="img-fluid"
                    src={baseUrl + service?.bannerImage}
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* winning sec ends here */}

      <Footer />
    </>
  );
};

export default Strategy;
