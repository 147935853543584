import React from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import PageHeader from "../../Components/PageHeader";
import { yourippost } from "../../network/Network";

<style>
    
</style>

const Submityourip =()=>{

  const [name, setName] = useState("");
  const [office, setOffice] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [cell, setCell] = useState("");
  const [company, setCompany] = useState("");
  const [time, setTime] = useState("");
  const [address, setAddress] = useState("");
  const [contacttime, setContacttime] = useState("");
  const [loading, setLoading] = useState(false);
  const [settingData, setSettingData] = useState([]);
  function onChange(value) {
    console.log("Captcha value:", value);
  }

  const YouripHandler = (e) => {
    e.preventDefault();
    console.log("run");
    setLoading(true);
    if (!name || !company || !email || !office || !cell || !address || !time ) {
      toast.error("Please Enter All Fields");
      setLoading(false);
      return;
    }
    if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      toast.error("Invalid Email");
      setLoading(false);
      return;
    }
    if (phone.length > 11) {
      toast.error("The contact number must be at least 10 characters.");
      setLoading(false);
      return;
    }
  
    console.log("run2");
    let data = {
      name: name,
      company: company,
      office: office,
      email: email,
      cell: cell,
      address: address,
      time: time,
      
    };
    console.log(data);
    yourippost(data)
      .then((res) => {
        setLoading(false);
        console.log(res);
        toast.success("Successfully send");
        setName("");
        setOffice("");
        setEmail("");
        setPhone("");
        setCell("");
        setCompany("");
        setTime("");
        setAddress("");
       
      })
      .catch((err) => {
        toast.error(err?.response?.data?.errors);
        setLoading(false);
        console.log(err);
      });
  };

    return (
        <>
        <Header />
       <PageHeader pagename="Agency IP" pagelinkname="Services" />

        {/* Contact section  */}
      <section className="Contact-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="contact-all">
                <div className="contact-head">
                  <h2>
                  Submit Your IP <span> For Review</span>
                  </h2>
                  <div className="blue-divider"></div>

                  {/* <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                  </p> */}
                </div>

                <form>
                  <div className="row">
                    <div className="col-md-6">
                      <div class="form-group">
                        <label for="exampleInputEmail1">Name*</label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Julia"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div class="form-group">
                        <label for="exampleInputEmail1">Company</label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Julia"
                          value={company}
                          onChange={(e) => setCompany(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div class="form-group">
                        <label for="exampleInputEmail1">Email</label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="you@example.com"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div class="form-group">
                        <label for="exampleInputEmail1">Office Number</label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="123456789"
                          value={office}
                          onChange={(e) => setOffice(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div class="form-group">
                        <label for="exampleInputEmail1">Cell Number</label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="123456789"
                          value={cell}
                          onChange={(e) => setCell(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div class="form-group">
                        <label for="exampleInputEmail1">Best Time To Contact</label>
                        <input
                          type="datetime-local"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="123456789"
                          value={time}
                          onChange={(e) => setTime(e.target.value)}
                        />
                      </div>
                    </div>


                    <div className="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlTextarea1">
                        Address
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="123456789"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="submitbtn">
                      <button
                          onClick={(e) => YouripHandler(e)}
                      >submit</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of Contact section  */}
        <Footer />
        </>
    )
}

export default Submityourip;