import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import PageHeader from "../../Components/PageHeader";
import { experience } from "../../constant";
import sfimg from "../../assets/img/sfimg.webp";
import { servicesgetbyslug } from "../../network/Network";
import { baseUrl } from "../../network/ApiUrl";

const Sherwoodfinance = () => {
  const [slug, setSlug] = useState("ip-monitize");
  const [service, setService] = useState([]);
  const [heading, setHeading] = useState("");
  const [innerHeading, setinnerHeading] = useState("");
  const [short, setShort] = useState("");
  const [bannerImage, setBannerImage] = useState(null);
  const [newFile, setNewFile] = useState();
  const [fileupload, setFileupload] = useState();
  const [content, setContent] = useState("");

  // Sherwood Get Api
  useEffect(() => {
    let data = {
      slug: `/ip-monitize`,
    };
    servicesgetbyslug(data)
      .then((res) => {
        console.log(res, "rrrr");
        setService(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [slug]);
  console.log("ser", service);

  // =====================================
  return (
    <>
      <Header />
      {/* <PageHeader pagename="agencyIP | Monetizing your IP " pagelinkname="Services" /> */}
      <PageHeader pagename={service?.heading} pagelinkname="Services" />

      {/* winning sec starts here */}
      <section className="winning-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="content-wrapper">
                <p dangerouslySetInnerHTML={{ __html: service?.content }} />
              </div>
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="img-wrapper img-effect">
                <figure>
                  {/* <img src={sfimg} className="img-fluid" /> */}
                  <img
                    className="img-fluid"
                    src={baseUrl + service?.bannerImage}
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* winning sec ends here */}

      {/* Experience sec starts here */}
      <section className="Experience-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <div className="img-wrapper">
                <figure>
                  {/* <img src={experience} className="experience-img" /> */}
                  <img
                    className="img-fluid"
                    src={baseUrl + service?.data?.section_two_image}
                  />
                </figure>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="content-wrapper">
                {/* <h3>
                  Unique <span> Insights</span>
                </h3>
                <p>
                  We have more than old friends to try to monetize your IP. We
                  go deep assisting with the best strategy with you and/or your
                  team. On numerous occasions, working with our clients we
                  uncover unique opportunities that will assist in higher values
                  for your IP. Your Success, is Our Success. That is how we
                  work.
                </p>
                <p>
                  Are you paying for maintenance fees, on patents that are no
                  longer used? Give us a call to find out how we can assist in
                  monetizing your IP.
                </p> */}
                <p
                  dangerouslySetInnerHTML={{
                    __html: service?.data?.section_two_content,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Experience sec ends here */}
      <Footer />
    </>
  );
};

export default Sherwoodfinance;
