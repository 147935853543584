import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import PageHeader from "../../Components/PageHeader";
import Finaltax from "../../assets/img/Finaltax.webp";
import { useState } from "react";
import { useEffect } from "react";
import { servicesgetbyslug } from "../../network/Network";
import { baseUrl } from "../../network/ApiUrl";

const Finalreturntax = () => {
  const [slug, setSlug] = useState("final-tax-returns");
  const [service, setService] = useState([]);
  const [heading, setHeading] = useState("");
  const [innerHeading, setinnerHeading] = useState("");
  const [short, setShort] = useState("");
  const [bannerImage, setBannerImage] = useState(null);
  const [newFile, setNewFile] = useState();
  const [fileupload, setFileupload] = useState();
  const [content, setContent] = useState("");
  useEffect(() => {
    let data = {
      slug: `/final-tax-returns`,
    };
    servicesgetbyslug(data)
      .then((res) => {
        console.log(res, "rrrr");
        setService(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [slug]);
  console.log("ser",service)
  return (
    <>
      <Header />
      <PageHeader pagename="Final Tax Returns " pagelinkname="Services" />

      {/* winning sec starts here */}
      <section className="winning-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="content-wrapper Monetization ">
                {/* <h3>Paying Taxes Is Not a Burden but a Duty</h3> */}
                <h3>{service?.heading}</h3>
                <div className="blue-divider"></div>
                {/* <h5>Paying Taxes Is Not A Burden But Rather A Duty</h5> */}
                {/* <p>
                Filing your final tax return can be exhausting as you are already anxious and upset about your company’s fate. That is where Sherwood comes in.
                </p>
                <p>
                We have a team of experienced and skilled professionals to file your company tax returns. 
                </p> */}
                 <p dangerouslySetInnerHTML={{ __html: service?.content }} />
              </div>
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="img-wrapper img-effect">
                <figure>
                  {/* <img src={Finaltax} className="img-fluid" /> */}
                  <img
                    className="img-fluid"
                    src={baseUrl + service?.bannerImage}
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* winning sec ends here */}
      <Footer />
    </>
  );
};

export default Finalreturntax;
