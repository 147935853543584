import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import PageHeader from "../../Components/PageHeader";
import liquid from "../../assets/img/liquid.webp";
import Lt from "../../assets/img/Lt.webp";
import lt1 from "../../assets/img/lt1.webp";
import lt2 from "../../assets/img/lt2.webp";
import lt3 from "../../assets/img/lt3.webp";
import lt4 from "../../assets/img/lt4.webp";
import ipm from "../../assets/img/ipm.webp";
import dc2 from "../../assets/img/debtnew.jpeg";
import { Link } from "react-router-dom";
import { servicesgetbyslug } from "../../network/Network";
import { baseUrl } from "../../network/ApiUrl";

const Lta = () => {
  const [slug, setSlug] = useState("lta");
  const [service, setService] = useState([]);
  const [heading, setHeading] = useState("");
  const [innerHeading, setinnerHeading] = useState("");
  const [short, setShort] = useState("");
  const [bannerImage, setBannerImage] = useState(null);
  const [newFile, setNewFile] = useState();
  const [fileupload, setFileupload] = useState();
  const [content, setContent] = useState("");

  // Lta Get Api 
  useEffect(() => {
    let data = {
      slug: `/lta`,
    };
    servicesgetbyslug(data)
      .then((res) => {
        console.log(res, "rrrr");
        setService(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [slug]);
// ===================================
  const Advisory_services = [
    {
      id: 1,
      name: "Wind-Down",
      img: lt1,
      link: "/winddown",
      des: "When the tough decision has been made, the Sherwood team has the experience to wind-down the Company. We can Collect any receivables, sell all inventory and other assets, wind-down the Company and/or facilities, get closure to the Company and monetizing all assets of the Company, sell any stock and then distribute the funds.",
      
    },
    {
      id: 2,
      name: "Debt Collection",
      img: dc2,
      link: "/Debtcollection",
      des: "Increase your productivity and acquire funds via excellent debt collection",
    },
    {
      id: 3,
      name: "Full Monetization",
      img: ipm,
      link: "/Monitization",
      des: "From receivables, liquidation, cleanup and everything that needs to be done for final closure ",
    },
    {
      id: 4,
      name: "Final Tax Returns",
      img: lt3,
      link: "/Finalreturntax",
      des: "Calculate your tax liability and much more with Sherwood Partners ",
    },
    {
      id: 5,
      name: "Distribution Of Funds / Stock",
      img: lt4,
      link: "/Funds",
      des: "Distribute funds flawlessly to gain trust of everyone ",
    },
  ];

  return (
    <>
      <Header />
      <PageHeader
        pagename="Liquidating Trust Agents | Distribution Agents"
        pagelinkname="Services"
      />

      {/* winning sec starts here */}
      <section className="winning-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="content-wrapper Monetization ">
                <h3>
                {/* Liquidating Trust Agents  <span>| Distribution Agents</span> */}
                {service?.heading}
                </h3>
                <div className="blue-divider"></div>
                   <p dangerouslySetInnerHTML={{ __html: service?.content }} />
              </div>
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="img-wrapper img-effect">
                <figure>
                  {/* <img src={Lt} className="img-fluid" /> */}
                  <img
                    className="img-fluid"
                    src={baseUrl + service?.bannerImage}
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* winning sec ends here */}

      {/* Advisory sec starts here */}
      <section className="Advisory-sec">
        <div className="container">
          <div className="row">
            {service?.homeServices?.map((data, index) => {
              return (
                <div className="col-lg-4">
                  <div
                    className={"Advisory-card-wrapper Advisory-card-" + data.id}
                    onClick={() =>
                      (window.location.href = `${data?.slug}`)
                    }
                  >
                    <div className="Advisory-img-wrapper">
                      <figure>
                        <img src={baseUrl +data?.bannerImage} className="img-fluid" />
                      </figure>
                    </div>
                    <div className="Advisory-content-wrapper">
                      <h4>
                        {data.heading.split(" ")[0]}{" "}
                        <span>{data.heading.split(" ").slice(1).join(" ")}</span>
                      </h4>
                      <div className="blue-divider"></div>
                      {/* <p>{data.content}</p> */}
                      <p dangerouslySetInnerHTML={{__html: data?.short}} />
                      <Link to={`/${ data?.slug }`}>
                        Learn more <i className="fa fa-angle-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      {/* Advisory sec ends here */}
      <Footer />
    </>
  );
};

export default Lta;
