import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import PageHeader from "../../Components/PageHeader";
import firstimg from "../../assets/img/firstimg.webp";
import { useState } from "react";
import { useEffect } from "react";
import { servicesgetbyslug } from "../../network/Network";
import { baseUrl } from "../../network/ApiUrl";

const Firsttime = () => {
  const [slug, setSlug] = useState("firsttime");
  const [service, setService] = useState([]);
  const [heading, setHeading] = useState("");
  const [innerHeading, setinnerHeading] = useState("");
  const [short, setShort] = useState("");
  const [bannerImage, setBannerImage] = useState(null);
  const [newFile, setNewFile] = useState();
  const [fileupload, setFileupload] = useState();
  const [content, setContent] = useState("");

  // Firsttime Get Api
  useEffect(() => {
    let data = {
      slug: `/firsttime`,
    };
    servicesgetbyslug(data)
      .then((res) => {
        console.log(res, "rrrr");
        setService(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [slug]);
  console.log("ser", service);
  // ===================================
  return (
    <>
      <Header />
      <PageHeader pagename={service?.heading} pagelinkname="Services" />
      {/* winning sec starts here */}
      <section className="winning-sec first-time-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
               <p dangerouslySetInnerHTML={{ __html: service?.content }} />
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="img-wrapper img-effect">
                <figure>
                  {/* <img src={firstimg} className="img-fluid" /> */}
                    <img
                    className="img-fluid"
                    src={baseUrl + service?.bannerImage}
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* winning sec ends here */}
      <Footer />
    </>
  );
};

export default Firsttime;
