import React, { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  footer_logo,
  log,
  logo,
  linkedin,
  footlogo,
  linkdenss,
  newfooterlogo,
} from "../constant";
import { baseUrl } from "../network/ApiUrl";
import { newsletterget, newspostapi, settingget } from "../network/Network";

const Footer = () => {
  const [newsletterdata, setNewsletterdata] = useState([]);
  const [setting, setSetting] = useState([]);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);


  const newslet = (e) => {
    e.preventDefault();
    console.log("run");
    setLoading(true);
    if (!email) {
      toast.error("Please Enter Email");
      setLoading(false);
      return;
    }

    if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      toast.error("Invalid Email");
      setLoading(false);
      return;
    }

   
    // if (!firstName || !lastName || !email || !phone || !message || !company || !purpose ) {
    //   toast.error("Please Enter All Fields");
    //   setLoading(false);
    //   return;
    // }
    // if (
    //   !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    //     email
    //   )
    // ) {
    //   toast.error("Invalid Email");
    //   setLoading(false);
    //   return;
    // }
    // if (phone.length < 10) {
    //   toast.error("The contact number must be at least 10 characters.");
    //   setLoading(false);
    //   return;
    // }
    // if (message.length < 20) {
    //   toast.error("The message must be at least 20 characters");
    //   setLoading(false);
    //   return;
    // }
    console.log("run2");
    let data = {
    
      email: email,
    };
    console.log(data);
    newspostapi(data)
      .then((res) => {
        setLoading(false);
        console.log(res);
        toast.success("Successfully send");
        setEmail("");
      })
      .catch((err) => {
        toast.error(err?.response?.data?.errors);
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    newsletterget()
      .then((res) => {
        console.log(res);
        setNewsletterdata(res?.data?.response?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    settingget()
      .then((res) => {
        console.log(res);
        setSetting(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      {/* <!-- Footer Start Here --> */}
      <footer>
        <div className="container">
          <div className="newsletter">
            <div className="row align-items-center">
              <div className="col-lg-7">
                <div className="heading">
                  <h4>Subscribe to Our Newsletter</h4>
                  {/* <p>
                    uspendisse mollis ante imperdiet, dictum sapien eu, suscipit
                    turpis. Curabitur enim purus, dignissim non
                  </p> */}
                </div>
              </div>
              <div className="col-lg-5">
                <div className="newsletter_box">
                  <div className="form-group">
                    <input
                      type="email"
                      placeholder="Your Email Addrress"
                      className="form-control"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <button 
                      onClick={(e) => newslet(e)}
                    className="btn-sherwood">Submit</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 p-0 m-0">
              <div className="logoDv">
                <figure>
                  {/* <img src={newfooterlogo} className="img-fluid" /> */}
                  <img
                            className="img-fluid"
                            width={"25%"}
                            src={baseUrl + setting?.logo}
                          />
                </figure>
              </div>
              {/* <p className="for-font-size">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.Lorem Ipsum has been the industry's standard dummy text
                .
              </p> */}
              <div className="button-group">
                <a
                  href="http://www.proofofclaims.com/"
                  target="blank"
                  className="btn-sherwood"
                >
                  Proof of Claims Form
                </a>
              </div>
            </div>
            <div className="col-lg-5 p-0 m-0">
              <div className="footer-content scnd-content">
                <div className="head">
                  <h4>Quick links</h4>
                </div>
                <ul className="footer-link">
                  <li>
                    <Link to="/sherwood-partners">Sherwood Partners</Link>
                    {/* <a href="#">SHERWOOD PARTNERS</a> */}
                  </li>
                  <li>
                    {/* <Link to="/IPmonetization">agencyIP Finance</Link> */}
                    <Link to="/ip-monitize">
                      {" "}
                      Lending or purchasing your IP
                    </Link>
                    {/* <a href="#">SHERWOOD FINANCE</a> */}
                  </li>
                  <li>
                    <Link to="/wellness-reviews">Wellness Reviews</Link>
                    {/* <a href="#">WELLNESS REVIEW</a> */}
                  </li>
                  <li>
                    <Link className="agencyip-name" to="/agency-ip">
                      agency<span className="Ip-uppercase">IP</span>{" "}
                    </Link>
                    {/* <a href="#">AGENCY IP</a> */}
                  </li>
                  <li>
                    <Link to="/lta">
                      Liquidation Trust Agents | Distribution Agents
                    </Link>
                    {/* <a href="#">
                      lIQUIDATION TRUST AGENTS | DISTRIBUTION AGENTS
                    </a> */}
                  </li>
                  <li>
                    <Link to="/firsttime">
                      WHEN YOU WANT IT DONE RIGHT, THE FIRST TIME
                    </Link>
                    {/* <a href="#">WHEN YOU WANT IT DONE RIGHT, THE FIRST TIME </a> */}
                  </li>
                  {/* <li>
										<Link to="/Faq">FAQ</Link>
									</li> */}
                </ul>
              </div>
            </div>
            <div className="col-lg-3 p-0 m-0">
              <div className="footer-content">
                <div className="head">
                  <h4>CONTACT US</h4>
                </div>
                <ul className="info">
                  <li>{setting?.title}</li>
                  {/* <li> SILICON VALLEY | LOS ANGELES | NEW YORK </li> */}
                  <li> {setting?.address}</li>
                  <li>
                    <a href="tel:650-454-8001">{setting?.phone}</a>
                  </li>
                  <li>
                    <a href="#">{setting?.fax}</a>
                  </li>
                  <li>
                    <a href="mailto:INFO@SHERWOODPARTNERS.COM">
                    {setting?.email}
                    </a>
                  </li>
                  <li>
                    
                    <a
                      href={setting?.linkedin}
                      target="blank"
                    >
                      <img src={linkdenss} className="img-fluid" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="copyright text-center">
            <p>{setting?.copyWrite}</p>
          </div>
        </div>
      </footer>
      {/* <!-- Footer End Here --> */}
    </>
  );
};

export default Footer;
