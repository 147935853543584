import React from 'react';
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import PageHeader from "../../Components/PageHeader";
import expic from "../../assets/img/expic.webp";
import liquid from "../../assets/img/liquid.webp";
import { experience } from "../../constant";
import { useState } from "react";
import { servicesgetbyslug } from "../../network/Network";
import { useEffect } from "react";
import { baseUrl } from "../../network/ApiUrl";

function Exper() {
    const [slug, setSlug] = useState("our-experince");
    const [service, setService] = useState([]);
    const [heading, setHeading] = useState("");
    const [innerHeading, setinnerHeading] = useState("");
    const [short, setShort] = useState("");
    const [bannerImage, setBannerImage] = useState(null);
    const [newFile, setNewFile] = useState();
    const [fileupload, setFileupload] = useState();
    const [content, setContent] = useState("");
    useEffect(() => {
      let data = {
        slug: `/our-experince`,
      };
      servicesgetbyslug(data)
        .then((res) => {
          console.log(res, "rrrr");
          setService(res?.data?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }, [slug]);
  return (
    <>
          <Header />
      <PageHeader
        pagename="Interim Management"
        pagelinkname="Interim Management"
      />

      {/* winning sec starts here */}
      <section className="winning-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="content-wrapper">
                <h3>{service?.heading}</h3>
                <div className="blue-divider"></div>
                <p dangerouslySetInnerHTML={{ __html: service?.content }} />
              </div>
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="img-wrapper img-effect">
                <figure>
                  <img
                    className="img-fluid"
                    src={baseUrl + service?.bannerImage}
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* winning sec ends here */}

      {/* Experience sec starts here */}
      <section className="Experience-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <div className="img-wrapper">
                <figure>
   
                  <img
                    className="img-fluid"
                    src={baseUrl + service?.data?.section_two_image}
                  />
                </figure>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="content-wrapper">
                <p dangerouslySetInnerHTML={{ __html: service?.data?.section_two_content }} />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Experience sec ends here */}
      <Footer />
    </>
  )
}

export default Exper