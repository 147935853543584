import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { blog2, blog3, blogs1 } from "../constant";
import { baseUrl } from "../network/ApiUrl";
import { news } from "../network/Network";

const Newscompo = () => {
  const [Newsstoress, setNewsstoress] = useState([]);
  const [loading, setLoading] = useState(false);
  const [spinLoad, setSpinLoad] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState();
  let limit = 10;
  const Advisory_services = [
    {
      id: 1,
      name: "Who will save the startups?",
      img: blogs1,
      des: "Marty Pichinson on who will survive the coming economic crisis.",
      date: "02",
      mon: "Mar",
    },
    {
      id: 2,
      name: 'Business is booming for Silicon Valley "undertaker"',
      img: blog2,
      des: "More from Martin Pichinson on the unprecedented damage to tech startups from the coronavirus.",
      date: "05",
      mon: "jun",
    },
    {
      id: 3,
      name: 'The "Undertaker Of Silicon Valley" Stays Busy As Startups Lay Off Thousands (with audio)',
      img: blog3,
      des: "Martin Pichinson on the ongoing COVID-19 impact.",
      date: "05",
      mon: "Mar",
    },
  ];
  // const Advisory_services = [
  //   {
  //     id: 1,
  //     name: "News Name",
  //     img: gallery11,
  //     des: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque vulputate eros mollis volutpat ultricies. Interdum et malesuada fames ac ante ipsum primi",
  //   },
  //   {
  //     id: 2,
  //     name: "News Name",
  //     img: gallery12,
  //     des: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque vulputate eros mollis volutpat ultricies. Interdum et malesuada fames ac ante ipsum primi",
  //   },
  //   {
  //     id: 3,
  //     name: "News Name",
  //     img: gallery12,
  //     des: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque vulputate eros mollis volutpat ultricies. Interdum et malesuada fames ac ante ipsum primi",
  //   },
  //   {
  //     id: 4,
  //     name: "News Name",
  //     img: gallery11,
  //     des: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque vulputate eros mollis volutpat ultricies. Interdum et malesuada fames ac ante ipsum primi",
  //   },
  //   {
  //     id: 5,
  //     name: "News Name",
  //     img: gallery12,
  //     des: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque vulputate eros mollis volutpat ultricies. Interdum et malesuada fames ac ante ipsum primi",
  //   },

  //   {
  //     id: 6,
  //     name: "News Name",
  //     img: gallery12,
  //     des: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque vulputate eros mollis volutpat ultricies. Interdum et malesuada fames ac ante ipsum primi",
  //   },

  //   {
  //     id: 7,
  //     name: "News Name",
  //     img: gallery12,
  //     des: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque vulputate eros mollis volutpat ultricies. Interdum et malesuada fames ac ante ipsum primi",
  //   },
  //   {
  //     id: 8,
  //     name: "News Name",
  //     img: gallery12,
  //     des: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque vulputate eros mollis volutpat ultricies. Interdum et malesuada fames ac ante ipsum primi",
  //   },

  //   {
  //     id: 9,
  //     name: "News Name",
  //     img: gallery12,
  //     des: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque vulputate eros mollis volutpat ultricies. Interdum et malesuada fames ac ante ipsum primi",
  //   },
  // ];

  useEffect(() => {
    setSpinLoad(true);
    news()
      .then((res) => {
        console.log(res);
        setNewsstoress(res?.data?.data);
        // dispatch(setblogstoress(res?.data?.response?.data));
        setSpinLoad(false);
        const total = res?.data?.response?.data?.total;
        setPageCount(Math.ceil(total / limit));
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.data?.message);
        setSpinLoad(false);
      });
  }, [limit, currentPage]);

  return (
    <>
      {/* Advisory sec starts here */}
      <section className="Advisory-sec News-sec">
        <div className="container">
          <div className="row">
            {Newsstoress.filter((value , key)=> key < 3 ).map((data, index) => {
              return (
                <div className="col-lg-4" key={index}>
                  <div
                    className={"Advisory-card-wrapper Advisory-card-" + data.id}
                  >
                    <div className="Advisory-img-wrapper">
                      <figure>
                      <img
                            src={`${baseUrl}${data?.image}`}
                            className="image-fluid"
                          />
                      </figure>
                    </div>
                    <div className="Advisory-content-wrapper">
                      <h4>
                        {data.title.split(" ")[0]}{" "}
                        <span>{data.title.split(" ").slice(1).join(" ")}</span>
                      </h4>
                      <div className="blue-divider"></div>
                      <p>{data.short}</p>

                      <div className="news-date-day">
                        {/* <h4>{data.date}</h4> */}
                        <h5>{ moment(data?.created_at).format(" MMM Do") }</h5>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      {/* Advisory sec ends here */}
    </>
  );
};
export default Newscompo;
