import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import winddo from "../../assets/img/winddo.webp";
import PageHeader from "../../Components/PageHeader";
import { useState } from "react";
import { useEffect } from "react";
import { servicesgetbyslug } from "../../network/Network";
import { baseUrl } from "../../network/ApiUrl";

const Wind = () => {
  const [slug, setSlug] = useState("wind-down");
  const [service, setService] = useState([]);
  const [heading, setHeading] = useState("");
  const [innerHeading, setinnerHeading] = useState("");
  const [short, setShort] = useState("");
  const [bannerImage, setBannerImage] = useState(null);
  const [newFile, setNewFile] = useState();
  const [fileupload, setFileupload] = useState();
  const [content, setContent] = useState("");
  useEffect(() => {
    let data = {
      slug: `/wind-down`,
    };
    servicesgetbyslug(data)
      .then((res) => {
        console.log(res, "rrrr");
        setService(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [slug]);
  console.log("ser",service)
  return (
    <>
      <Header />
      <PageHeader pagename={service?.heading} pagelinkname="Services" />

      {/* winning sec starts here */}
      <section className="winning-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="content-wrapper Monetization ">
                {/* <h3>Wind-Down</h3> */}
                {/* <h3></h3>
                <div className="blue-divider"></div> */}
                {/* <p>
                With our experience and expertise you can get numerous advantages.
                </p> */}
                {/* <p>With our wind-down services, you can expect: </p>
                <h3>Debt Negotiations</h3> */}
                {/* <p>
                A challenging situation is when the company cannot repay its current debts and has little chance of doing so. Debt negotiations can assist in extending your runway and reducing your burn. Insolvency can stop you from continuing to do business and a successful debt negotiation can be a method for settling outstanding debts to optimize ongoing revenues for creditors. Sherwood Partners has been successful in handling all formalities, working towards a resolution.
                </p>
               
                <h3>Suspension of Judicial Proceedings</h3>
                <p>When a business declares bankruptcy, all legal proceedings against it cease. If you are not personally accountable for a business debt.</p>
                <h3>Abandoning Leases</h3>
                <p>Liquidation often means that no additional payments will be paid on lease or hire/purchase arrangements. The leasing business may be able to collect any unpaid arrears from insolvency practitioners and other creditors if any are owed. If you sign a property leasing agreement, you may be asked to provide personal guarantees. We help you review your obligations carefully to see whether you will be held personally liable for the duration of the lease.</p> */}
                 <p dangerouslySetInnerHTML={{ __html: service?.content }} />
                
                
              </div>
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="img-wrapper img-effect">
                <figure>
                  {/* <img src={winddo} className="img-fluid" /> */}
                  <img src={baseUrl +service?.bannerImage} className="img-fluid" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* winning sec ends here */}
      <Footer />
    </>
  );
};

export default Wind;
