import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import PageHeader from "../../Components/PageHeader";
// css file
import "../../assets/css/about.css";
// image import starts here
import {
  winning,
  experience,
  Business,
  singleimg16,
  team_member3,
  NoPicture,
} from "../../constant";
import { Link, useNavigate } from "react-router-dom";
import { aboutget } from "../../network/Network";
import { baseUrl } from "../../network/ApiUrl";
// image import ends here

const About = () => {
  const [settingData, setSettingData] = useState(null);
  const [heading, setHeading] = useState([]);
  const [bannerImage, setBannerImage] = useState([]);
  const [content, setContent] = useState([]);

  useEffect(() => {
    aboutget()
      .then((res) => {
        console.log(res);
        setSettingData(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  console.log("hello", settingData);
  let navigate = useNavigate();
  const teammembers = [
    {
      id: 1,
      name: "Co-President & Founders",
      img: singleimg16,
      path: "/Presidents",
    },
    {
      id: 2,
      name: "Professionals",
      img: team_member3,
      path: "/SherwoodProfessionals",
    },
  ];
  return (
    <>
      <Header />
      {/* page header starts here */}
      <PageHeader pagename="About Us" pagelinkname="About Us" />
      {/* page header ends here */}
      {/* winning sec starts here */}
      <section className="winning-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="content-wrapper">
                <p dangerouslySetInnerHTML={{ __html: settingData?.content }} />
              </div>
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="img-wrapper">
                <figure>
                  {/* <img src={winning} className="img-fluid" /> */}
                  <img
                    className="img-fluid"
                    src={baseUrl + settingData?.bannerImage}
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* winning sec ends here */}
      {/* Experience sec starts here */}
      <section className="Experience-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="content-wrapper">
                <p
                  dangerouslySetInnerHTML={{
                    __html: settingData?.smallContent,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Experience sec ends here */}
      {/* Business  sec starts here */}
      <section className="Business-sec">
        <div className="container">
          <div className="row for-revers-row">
            <div className="col-lg-6">
              <div className="content-wrapper">
                <p
                  dangerouslySetInnerHTML={{ __html: settingData?.content3 }}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="img-wrapper">
                <figure>
                  {/* <img src={Business} className="img-fluid" /> */}
                  <img
                    className="img-fluid"
                    src={baseUrl + settingData?.bannerImage3}
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Business  sec ends here */}
      {/* our-Team  sec starts here */}
      <section className="team-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="content-wrapper">
                <h3>
                  Our <span>Team</span>
                </h3>
                <p>A Team of Experts Dedicated to Serving You</p>
              </div>
            </div>

            <div className="col-md-6">
              {/* <div className={"team-card-wrapper team-card-no-" + data?.id}> */}
              <div className="team-img-wrapper">
                <figure>
                  <img src={baseUrl +settingData?.image1} className="img-fluid" />
                </figure>
              </div>
              <div className="team-name-wrapper">
                <h5>{settingData?.name1}</h5>
              </div>

              {/* </div> */}
            </div>
            <div className="col-md-6">
              {/* <div className={"team-card-wrapper team-card-no-" + data?.id}> */}
              <div className="team-img-wrapper">
                <figure>
                  <img src={baseUrl + settingData?.image2} className="img-fluid" />
                </figure>
              </div>
              <div className="team-name-wrapper">
                <h5>{settingData?.name2}</h5>
              </div>

              {/* </div> */}
            </div>
          </div>
        </div>
      </section>
      {/* our-Team  sec ends here */}
      <Footer />
    </>
  );
};

export default About;
