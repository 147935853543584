import React, { useEffect, useState } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import PageHeader from "../../Components/PageHeader";
import "../../assets/css/president.css";
// image import starts here
import {
  winning,
  manager_img,
  michael_maidy,
  martin_pichinson,
  singleimg16,
  singleimg17,
  pichison,
} from "../../constant";
import { presidentget } from "../../network/Network";
import { baseUrl } from "../../network/ApiUrl";
// image import ends here

const Presidents = () => {
  const [service, setService] = useState([]);
  const [slug, setSlug] = useState("");
  const [form, setForm] = useState({
    slug: "",
    title1: "",
    image1: null,
    name1: "",
    content1: "",
    title2: "",
    image2: null,
    name2: "",
    content2: "",
  });
  useEffect(() => {
    presidentget()
      .then((res) => {
        setService(res?.data?.data);
        setForm((preval) => ({
          ...preval,
          title1: res?.data?.data?.title1,
          title2: res?.data?.data?.title2,
          name1: res?.data?.data?.name1,
          name2: res?.data?.data?.name2,
          slug: slug,
          content1: res?.data?.data?.content1,
          content2: res?.data?.data?.content2,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <Header />
      {/* page header starts here */}
      <PageHeader
        pagename="Our Co-Presidents & Founders"
        pagelinkname="About Us"
      />
      {/* page header ends here */}
      {/* winning sec starts here */}
      <section className="winning-sec manager-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="content-wrapper">
                <p dangerouslySetInnerHTML={{ __html: service?.content1 }} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="image1">
                <img src={baseUrl + service?.image1} className="img-fluid" />
                <div className="manager-card-wrapper">
                  <h5>{service?.title1}</h5>
                  <h3>{service?.name1}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* winning sec ends here */}
      {/* manager-sec starts here */}

      {/* manager-sec ends here */}

      <section className="winning-sec reverse-sec manager-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="image1">
                <img src={baseUrl + service?.image2} className="img-fluid" />
                <div className="manager-card-wrapper">
                  <h5>{service?.title2}</h5>
                  <h3> {service?.name2}</h3>
                </div>
              </div>
            </div>
            <div className="col-md-6 align-self-center">
              <div className="img-wrapper">
                <div className="content-wrapper">
                  <p dangerouslySetInnerHTML={{ __html: service?.content2 }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Presidents;
